@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }
}

.ProseMirror {
  @apply p-8;
}

:root {
  --primary-color: rgba(109, 40, 217);
  --focused-primary-color: rgba(87,32,173);
  --text-color-primary: #424242;
  --text-color-secondary: #656565;
  --bg-color: #fff;
  --bg-color-el-1: #fff;
  --focus-color: #e5e5e5;
  --placeholder-color: #ccc;
  --cursor-color: #222222;
}

[data-theme="dark"] {
  --primary-color: rgba(109, 40, 217);
  --focused-primary-color: rgba(87,32,173);
  --text-color-primary: #f5f5ff;
  --text-color-secondary: #c1c1c1;
  --bg-color: #222222;
  --bg-color-el-1: #313131fd;
  --focus-color: #494949fd;
  --placeholder-color: #313131fd;
  --cursor-color: #f7f7f7;
  background-color: var(--bg-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: rgba(109, 40, 217);
    --text-color-primary: #f5f5ff;
    --text-color-secondary: #c1c1c1;
    --bg-color: #222222;
    --bg-color-el-1: #313131fd;
    --focus-color: #494949fd;
    --placeholder-color: #313131fd;
    background-color: var(--bg-color);
  }

  [data-theme="light"] {
    --primary-color: rgba(109, 40, 217);
    --text-color-primary: #424242;
    --text-color-secondary: #656565;
    --bg-color: #fff;
    --bg-color-el-1: #fff;
    --focus-color: #e5e5e5;
    --placeholder-color: #ccc;
  }
}
